import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Layout from 'components/Layout';
import Wrapper from 'components/Wrapper';
import Content from 'components/Content';
import Form from 'components/Form';
import site from 'data/site';
import SEO from 'utils/SEO';

const Page = styled.div`
	display: grid;
	width: 100%;
	${theme.media.large} {
		grid-template-areas: 'main side' 'feed side';
		grid-template-columns: minmax(0, 1fr) auto;
	}
	${theme.media.notlarge} {
		grid-template-areas: 'main' 'side' 'feed';
		grid-template-columns: 100%;
	}
	grid-gap: ${theme.size(4)};
`;
const Main = styled.div`
	grid-area: main;
	background: ${theme.colors.background[0]};
	padding: ${theme.size(4)};
	> * {
		width: 100%;
	}
`;
const Side = styled.div`
	grid-area: side;
`;
const Feed = styled.div`
	grid-area: feed;
`;

export default props => {
	return (
		<Layout>
			<SEO title="Contact us" />
			<Wrapper>
				<Page>
					<Main>
						<Content>
							<h1>Contact us</h1>
							<p>
								If you have any questions regarding {site.text.logo.title} content, partnerships,
								advertising or just want to give us your feedback, feel free to contact us via the
								contact form below:
							</p>
							<Form
								input={[
									{
										id: 'email',
										type: 'email',
										title: 'Your email address',
										placeholder: 'jsmith@gmail.com',
										required: true,
									},
									{
										id: 'message',
										type: 'textarea',
										title: 'Your message',
										placeholder: 'Hey! I’m writing to ask you about...',
										required: false,
									},
								]}
								output={'bhn'}
								button={'Submit message'}
								intro={['']}
								outro={[
									'Thank you for your message!',
									'We will get in touch with you via the contact information you provided.',
								]}
							/>
						</Content>
					</Main>
				</Page>
			</Wrapper>
		</Layout>
	);
};
