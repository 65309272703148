import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import axios from 'axios';

const StyledWrapper = styled.div`
	.form {
		width: 100%;
		&__button {
			${theme.type.p1};
			font-weight: bold;
			display: block;
			margin: ${theme.size(6)} auto;
			width: 100%;
			max-width: 300px;
			padding: ${theme.size(2)} ${theme.size(6)};
			background: ${theme.colors.base[1]};
			border-radius: ${theme.size(1)};
			color: ${theme.colors.background[0]};
			outline: none;
			border: 0;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		&__head {
			margin-bottom: ${theme.size(4)};
			&Item {
				&:nth-of-type(1) {
					${theme.type.p1};
					color: ${theme.colors.foreground[0]};
					font-weight: bold;
					display: block;
				}
				&:nth-of-type(n + 2) {
					${theme.type.p2};
					color: ${theme.colors.foreground[1]};
					font-weight: 400;
					display: block;
				}
			}
		}
		&__item {
			display: block;
			width: 100%;
			padding: ${theme.size(4)} 0 ${theme.size(1)};
			&--hidden {
				display: none;
			}
			&Input {
				display: block;
				width: 100%;
				padding: ${theme.size(3)};
				border-radius: ${theme.size(1)};
				outline: none;
				border: 0;
				${theme.helpers.border};
				${theme.type.p2};
				&Option {
					display: block;
					input {
						margin-right: ${theme.size(2)};
					}
				}
				&[type='email'],
				&[type='text'] {
					max-width: 400px;
				}
			}
			textarea {
				width: 100%;
				max-width: 100%;
				min-width: 100%;
				height: 200px;
				min-height: 200px;
			}
			&Title {
				${theme.type.p2};
				color: ${theme.colors.foreground[0]};
				display: block;
				font-weight: bold;
				margin: 0 0 ${theme.size(1)};
			}
			&Subtitle {
				${theme.type.p2};
				color: ${theme.colors.foreground[1]};
				display: block;
				width: 100%;
				margin: -${theme.size(2)} 0 ${theme.size(1)};
			}
		}
	}
`;

export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sent: false,
		};
		this.handleForm = this.handleForm.bind(this);
	}
	handleFile(event) {
		event.persist();
		event.target.removeAttribute('data-file');
		if (event.target.files[0].size / 1000 < 200) {
			var formData = new FormData();
			var token = ['xoxp', '3732220563', '540397811424', '752942296369', 'c210430d61b8ec5089704fdcb53d534b'];
			formData.append('token', token.join('-'));
			formData.append('file', event.target.files[0]);
			event.target.setAttribute('data-file', '');
			axios({
				method: 'post',
				url: 'https://slack.com/api/files.upload',
				data: formData,
				config: { headers: { 'Content-Type': 'multipart/form-data' } },
			})
				.then(function(response) {
					event.target.setAttribute('data-file', response.data.file.url_private);
				})
				.catch(function(response) {});
		} else {
			alert('File size too large.');
		}
	}
	handleForm(event) {
		event.preventDefault();
		let data = [];
		Array.from(event.target.querySelectorAll('.form__item')).map((item, index) => {
			const type = item.querySelector('[name]').getAttribute('type');
			let value = '';
			switch (type) {
				case 'checkbox':
					value = [];
					Array.from(item.querySelectorAll('[name]:checked')).map(v => value.push(v.value));
					value = value.join(', ');
					break;
				case 'file':
					const file = item.querySelector('[data-file]');
					if (file) {
						value = file.getAttribute('data-file');
					}
					break;
				default:
					value = item.querySelector('[name]').value;
					break;
			}
			data.push({
				name: item.querySelector('[name]').getAttribute('name'),
				value: value,
			});
			return true;
		});
		axios
			.get('https://topmoneyguide-demo.netlify.app/.netlify/functions/send/', {
				params: { data: JSON.stringify(data), output: this.props.output },
			})
			.then(res => {
				this.setState({ sent: true });
			})
			.catch(err => {
				console.log('ERROR!', err);
			});
	}
	render() {
		return (
			<StyledWrapper>
				<form className="form" onSubmit={this.handleForm}>
					{!this.state.sent ? (
						<div>
							<div className="form__head">
								{this.props.intro.map((item, index) => (
									<p key={index} className="form__headItem">
										{item}
									</p>
								))}
							</div>
							{this.props.input.map((input, index) => {
								switch (input.type) {
									case 'text':
										return (
											<label className="form__item" key={index}>
												<p className="form__itemTitle">{input.title}</p>
												{input.subtitle && (
													<p className="form__itemSubtitle">{input.subtitle}</p>
												)}
												<input
													className="form__itemInput"
													name={input.id}
													type={input.type}
													placeholder={input.placeholder}
													required={input.required}
												/>
											</label>
										);
									case 'email':
										return (
											<label className="form__item" key={index}>
												<p className="form__itemTitle">{input.title}</p>
												{input.subtitle && (
													<p className="form__itemSubtitle">{input.subtitle}</p>
												)}
												<input
													className="form__itemInput"
													name={input.id}
													type={input.type}
													placeholder={input.placeholder}
													required={input.required}
												/>
											</label>
										);
									case 'number':
										return (
											<label className="form__item" key={index}>
												<p className="form__itemTitle">{input.title}</p>
												{input.subtitle && (
													<p className="form__itemSubtitle">{input.subtitle}</p>
												)}
												<input
													className="form__itemInput"
													name={input.id}
													type={input.type}
													placeholder={input.placeholder}
													required={input.required}
												/>
											</label>
										);
									case 'file':
										return (
											<label className="form__item" key={index}>
												<p className="form__itemTitle">{input.title}</p>
												{input.subtitle && (
													<p className="form__itemSubtitle">{input.subtitle}</p>
												)}
												<input
													className="form__itemInput"
													name={input.id}
													type={input.type}
													required={input.required}
													onChange={this.handleFile}
												/>
											</label>
										);
									case 'checkbox':
										return (
											<div className="form__item" key={index}>
												<p className="form__itemTitle">{input.title}</p>
												{input.subtitle && (
													<p className="form__itemSubtitle">{input.subtitle}</p>
												)}
												<div className="form__itemInput">
													{input.options.map((item, index) => (
														<label key={index} className="form__itemInputOption">
															<input name={input.id} type={input.type} value={item} />
															{item}
														</label>
													))}
												</div>
											</div>
										);
									case 'textarea':
										return (
											<label className="form__item" key={index}>
												<p className="form__itemTitle">{input.title}</p>
												{input.subtitle && (
													<p className="form__itemSubtitle">{input.subtitle}</p>
												)}
												<textarea
													className="form__itemInput"
													name={input.id}
													placeholder={input.placeholder}
													required={input.required}
												/>
											</label>
										);
									default:
										return false;
								}
							})}
							<div className="form__item form__item--hidden">
								<input
									type="hidden"
									name="__page"
									value={typeof window != 'undefined' && window.location.pathname}
								/>
							</div>
							<div className="form__item form__item--hidden">
								<input type="hidden" name="__date" value={new Date().toUTCString()} />
							</div>
							<div className="form__item form__item--hidden">
								<input
									type="hidden"
									name="__user"
									value={typeof navigator != 'undefined' && navigator.userAgent}
								/>
							</div>
							<button className="form__button" type="submit">
								{this.props.button}
							</button>
						</div>
					) : (
						<div>
							<div className="form__head">
								{this.props.outro.map((item, index) => (
									<p key={index} className="form__headItem">
										{item}
									</p>
								))}
							</div>
						</div>
					)}
				</form>
			</StyledWrapper>
		);
	}
}
